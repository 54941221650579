<template>
  <div id="terms-html2">
    <div>
      <p>
        El presente documento, tiene por finalidad dar a conocer los términos y condiciones de uso de los servicios
        prestados por la sociedad
        <b>CLICK2BUY S.A.S. (" Precompro")</b>sociedad comercial con domicilio en la ciudad
        de Bogotá D.C., Colombia, legalmente constituida, identificada con NIT No.
        <b>900.974.092 – 1</b>a través de
        las soluciones tecnológicas que implementa a favor de sus clientes (el Software”), así como de su página web (el
        “Sitio Web” y en conjunto con el Software las “Plataformas Tecnológicas”).
      </p>
      <p>
        <br/>Se le informa a quienes ingresen o se registren en las Plataformas Tecnológicas (en adelante los “Usuarios”
        o el
        “Usuario”), que las mismas son de propiedad o se encuentran bajo el control de Precompro. Así mismo, se le
        informa al Usuario que el acceso a las Plataformas Tecnológicas, implica el conocimiento, comprensión y
        aceptación sin reserva alguna de los términos y condiciones generales de uso previstos en este documento (en
        adelante los
        “Términos y Condiciones”).
      </p>
      <p>
        <br/>En caso de que el Usuario no se encuentre de acuerdo con las disposiciones aquí establecidas, deberá
        abstenerse
        de utilizar cualquiera de las Plataformas Tecnológicas.
      </p>
      <p>
        <br/>
        <b>DEFINICIONES</b>
      </p>
      <p>
        <br/>
        <b>Dispositivo:</b> Hace referencia al teléfono móvil, tableta, computador o en general cualquier
        dispositivo a partir del cual el Usuario pueda disponer de las Plataformas Tecnológicas.
      </p>
      <p>
        <b>Estatuto del Consumidor:</b> Hace referencia a ley 1480 de 2011, donde se dictan las disposiciones
        referentes a las relaciones de consumo entre productores y proveedores de bienes y servicios y los consumidores
        finales.
      </p>
      <p>
        <b>Plataformas Tecnológicas:</b> Se refiere al Software desarrollado por Precompro a favor del Restaurante para
        apoyar su proceso de gestión de reservas, al Sitio Web propiedad de Precompro ubicado en el siguiente dominio:
        https://precompro.com/ y a cualquier otro desarrollo tecnológico de Precompro.
      </p>
      <p>
        <b>No aparición:</b> Hace referencia a los eventos en que el Usuario no se presenta en el Restaurante
        seleccionado a la hora de la Reserva o Reserva con Anticipo realizada a través del Software, o cualquier otro
        evento que se califique en estos Términos y Condiciones como una No Aparición.
      </p>
      <p>
        <b>Restaurante:</b> Es el establecimiento de comercio dedicado a la venta y comercialización al público de
        comidas, alimentos preparados y bebidas y que tiene una relación comercial con Precompro para la implementación
        de el Software.
      </p>
      <p>
        <b>Reserva:</b> Hace referencia a la separación de una mesa o espacio en el del Restaurante para acceder a sus
        servicios en una fecha y hora específica. La Reserva puede realizarse a través de las siguientes modalidades:
        (i) de manera virtual; (ii) de manera telefónica; (iii) a través de aplicaciones de mensajería instantánea como
        Whatsapp, entre otras; y (iv) a través del Software.
      </p>
      <p>
        <b>Reserva con Anticipo:</b> Hace referencia a los eventos en los cuales, al momento de hacer una Reserva, el
        Usuario realiza el pago anticipado de un porcentaje del valor del servicio que prestará el Restaurante. El valor
        pagado anticipadamente podrá ser 100% consumible siempre que se cumplan las especificaciones previstas en los
        presentes Términos y Condiciones.
      </p>
      <p>
        <b>Sitio Web:</b> Página de internet ubicada en el dominio: www.precompro.com propiedad de Precompro, en la cual
        se encuentra información de orientación para los Usuarios y los clientes de Precompro, los términos y
        condiciones de uso de las Plataformas Tecnológicas y otras políticas de Precompro.
      </p>
      <p>
        <b>Software:</b> Hace referencia al desarrollo tecnológico que despliega Precompro a favor del Restaurante, para
        apoyar su proceso de gestión de reservas. El Software incluye la parametrización, customización y/o integración
        con otros sistemas del desarrollo tecnológico de Precompro, implementando a favor del Restaurante un sistema
        virtual, que permite a los comensales hacer la reserva de una mesa en su establecimiento.
      </p>
      <p>
        <b>Usuarios:</b> Persona natural o jurídica que: (i) realiza una Reserva ante un Restaurante, ya sea en la
        modalidad virtual, telefónica o a través de aplicaciones de mensajería instantánea; (ii)realiza un registro y
        utiliza el Software para realizar reservas en Restaurantes nacionales o internacionales que cuentan con la
        implementación de Precompro; o (iii)que ingresa y navega por el Sitio Web .
      </p>
      <p>
        <b>Ticket:</b> Se refiere a la confirmación de Reserva o a la confirmación de Reserva y pago (en los casos de
        Reservación con Anticipo) que recibe el Usuario vía correo electrónico ; mensaje de texto, aplicaciones de
        mensajería instantánea, como Whatsapp; o, a través de cualquier canal de comunicación de contacto que haya sido
        suministrado por el Usuario al momento de hacer una Reserva o hacer su registro parausar el Software.
      </p>
      <p>
        <br/>
        <b style="padding-left: 30px">I. SOBRE EL SOFTWARE Y SU FUNCIONAMIENTO</b>
      </p>
      <p>
        <br/>Precompro es un proveedor de servicios tecnológicos que pone a disposición de restaurantes y
        establecimientos
        que venden y comercializan al público alimentos preparados y bebidas, un Software que automatiza el proceso de
        reservación de mesas o espacios a comensales o usuarios. Así mismo, el Software le permite a los Restaurantes
        administrar y gestionar un sistema de Reservas.
      </p>
      <p>
        <br/>A través del Software, el Usuario realiza fácilmente una Reserva o Reserva con Anticipo y, culminado el
        proceso,
        recibirá un Ticket.
      </p>
      <p>
        <br/>El Usuario podrá acceder a una Reserva a través de los siguientes canales:
      </p>
      <p>
        <br/>(i) directamente en la página web del Restaurante y allí realiza la Reserva;
      </p>
      <p>
        <br/>(ii) a través de la integración del Software con Google, en este caso, el Usuario ingresa el nombre del
        Restaurante de su interés en el motor de búsquedas de Google, tras lo cual se desplegará la opción “RESERVAR UNA
        MESA”, dirigiendo al Usuario a la herramienta de reservación de servicios locales desarrollada por Google;
      </p>
      <p>
        <br/>(iii) a través de canales de comunicación telefónica;
      </p>
      <p>
        <br/>(iv) a través de aplicaciones de mensajería instantánea, como Whatsapp; o,
      </p>
      <p>
        <br/>(v) a través de los demás canales de comunicación virtuales o no, que el Restaurante ponga a disposición
        del
        Usuario.
      </p>
      <p>
        <br/>Toda la información del Usuario y la Reserva, independientemente del canal de comunicación a través del
        cual se
        realice la Reserva, ingresará al sistema de Reservas gestionado por el Restaurante.
      </p>
      <p>
        <br/>Es discrecional del Restaurante el canal de comunicación que ofrece a los Usuarios. 24 feb 2023
      </p>
      <p>
        <br/>
        <b style="padding-left: 30px">II. TIPOS DE RESERVA</b>
      </p>
      <p>
        <br/>Precompro informa al Usuario que el Restaurante podrá ofrecer reservas bajo las modalidades que el
        Restaurante
        considere necesario, las cuales a continuación se relacionan:
      </p>
      <div style="padding-left: 40px">
        <p>
          <br/>(i) Reserva o reserva sin anticipo: mediante la cual el Usuario podrá separar una mesa o un espacio al
          interior
          del Restaurante para usar sus servicios en la fecha y hora de su elección.
        </p>
        <p>
          (ii) Reserva con Anticipo: mediante la cual el Usuario además de separar una mesa o un espacio al interior del
          Restaurante, realiza el pago anticipado de una suma de dinero que solicita el Restaurante. Al precio total que
          cobra el Restaurante al Usuario por la prestación del servicio, se le descuenta el valor ya pagado por el
          Usuario al momento de realizar la Reserva con Anticipo. Es decir, el Usuario solo pagará del precio total,
          aquella parte que excede el valor pagado por concepto de Reserva con Anticipo.
        </p>
      </div>
      <p>Es discrecional del Restaurante la modalidad de Reserva que ofrece al Usuario .</p>
      <br/>
      <p>
        <b style="padding-left: 30px">III. SOBRE LA RELACIÓN CON LOS RESTAURANTES</b>
      </p>
      <br/>
      <p>
        Se informa al Usuario que su relación comercial y de consumo será directamente con el Restaurante
        <b>{{ vendor.displayName }}</b> , razón por la cual se realizan las siguientes especificaciones:
      </p>
      <br/>
      <p>
        <b>
          3.1. Especificaciones aplicables a las Reservas y a las Reservas con Anticipo:
          <br/>3.1.1. Tarifas
        </b>
      </p>
      <br/>
      <p>(i) Precompro no cobrará ningún cargo al Usuario por las Reservas hechas a través del Software.</p>
      <br/>
      <p>
        (ii) En todo caso, Precompro se reserva el derecho de modificar, cambiar, agregar, o eliminar las tarifas
        vigentes, en cualquier momento.
      </p>
      <br/>
      <p>
        <b>3.1.2. Identificación</b>
      </p>
      <br/>
      <p>
        (i) El Restaurante podrá solicitar al Usuario su identificación con el fin de confirmar la Reserva o la
        Reserva con Anticipo.
      </p>
      <br/>
      <p>
        (ii) De ser el caso, el Usuario deberá demostrar su identificación al momento de presentarse al
        Restaurante.
      </p>
      <br/>
      <p>
        (iii) En caso de que el Usuario no pueda presentar o demostrar su identificación al momento de la Reserva o de
        la Reserva con Anticipo, será tenido en cuenta en el sistema como una No Aparición y subsecuentemente, no podrá
        hacer uso de esta.
      </p>
      <br/>
      <p>
        <b>3.1.3. Modificaciones y/o cancelaciones para {{ vendor.displayName }}</b>
      </p>
      <br/>
      <p>
        (i) En caso de modificaciones a las condiciones iniciales de la Reserva o Reserva con Anticipo, la hora y
        fecha para la modificación de la Reserva o Reserva con Anticipo estarán condicionadas a la disponibilidad del
        Restaurante. El Usuario podrá pedir o hacer una modificación hasta con
        <b>{{ convertTime(params.updateTime) }}</b>
        horas de antelación a la fecha y hora original de la reserva .
      </p>
      <br/>
      <p>
        (ii) En caso de cancelaciones, el Usuario podrá pedir el reembolso de su dinero siempre y cuando haya
        realizado la cancelación con una antelación de
        <b>{{ convertTime(params.cancelledTime) }}</b> horas.
      </p>
      <br/>
      <p>
        (iii) Por lo tanto, si el Usuario no realiza la cancelación de su Reserva con Anticipo, con una antelación de
        <b>{{ convertTime(params.cancelledTime) }}</b> horas, no tendrá derecho a reembolso del dinero que haya abonado.
      </p>
      <br/>
      <p>
        (iv) El Usuario deberá acatar las políticas de cancelación y/o modificaciones adicionales determinadas por el
        Restaurante, en donde se indican precisiones sobre el procedimiento para cancelar o modificar la Reserva o
        Reserva con Anticipo.
      </p>
      <br/>
      <p>
        (v) Con la aceptación de los presentes Términos y Condiciones el Usuario declara conocer y aceptar las
        políticas internas de cancelación y/o modificaciones determinadas por el Restaurante.
      </p>
      <br/>
      <p>
        <b>3.1. Llegadas tarde</b>
      </p>
      <br/>
      <p>
        (i) El Restaurante dará
        <b>{{ params.anticipationUserTime }}</b> minutos de espera al Usuario, contados desde
        la hora estipulada en el Ticket, para que este se presente en el Restaurante y haga uso de su Reserva o Reserva
        con Anticipo. Pasado ese tiempo el Restaurante se reserva el derecho de cancelar la Reserva o Reserva con
        Anticipo y ocupar la mesa o espacio del Usuario.
      </p>
      <br/>
      <p>
        (ii) Si el Usuario se presenta
        <b>{{ params.anticipationUserTime }}</b> minutos o más después de pasada la hora de la Reserva
        o de la Reserva con Anticipo y no existe disponibilidad de mesas en el Restaurante, ni el Restaurante ni
        Precompro estarán obligados a garantizar el ingreso y ubicación del Usuario al Restaurante, como tampoco de
        modificar la Reserva. En todo caso, el Restaurante podrá a su entera discreción y de manera facultativa,
        permitir que el Usuario haga uso del Ticket después de transcurrido el tiempo máximo para redimir la Reserva o
        la Reserva con Anticipo.
      </p>
      <br/>
      <p>
        iii) Si en los eventos anteriores, el Usuario contaba con una Reserva con Anticipo, ni el Restaurante ni
        Precompro, estarán obligados a devolver o reembolsar el dinero pagado.
      </p>
      <br/>
      <p>
        <b>3.1.5. No Aparición</b>
      </p>
      <br/>
      <p>
        (i) Si el Usuario no se presenta en el Restaurante a la hora de la Reserva o a la hora de la Reserva con
        Anticipo realizada a través de cualquiera de los canales establecidos en la sección I, será catalogado como una
        No Aparición ante Precompro y ante el Restaurante, y perderá el derecho a la utilización del Ticket.
      </p>
      <br/>
      <p>
        (ii) En los casos de Reserva con Anticipo, no habrá lugar a devoluciones ni reembolsos del valor del pago
        anticipado realizado.
      </p>
      <br/>
      <p>
        (iii) Precompro se reserva el derecho de suspender o terminar la cuenta del Usuario, en aquellos eventos en
        que el Usuario incurra en una No Aparición.
      </p>
      <br/>
      <p>
        <b>3.1.6. Responsabilidad</b>
      </p>
      <br/>
      <p>
        Precompro no asume responsabilidad por la cancelación de la Reserva o Reserva con Anticipo, en forma
        unilateral por parte del Restaurante. Cualquier reclamación por cancelaciones no justificadas de la Reserva o de
        la Reserva con Anticipo, deberá ser elevada por el Usuario directamente ante el Restaurante.
      </p>
      <br/>
      <p>
        <b>3.1.7. Facturación</b>
      </p>
      <br/>
      <p>
        En atención a que la relación de consumo surge directamente entre el Restaurante y el Usuario, y es el
        Restaurante quien al final realiza el cobro por concepto de sus servicios y productos, la factura será entregada
        por el Restaurante que preste el servicio de Reserva o Reserva con Anticipo al Usuario . Así mismo, la factura
        incluirá cualquier impuesto aplicable de acuerdo con las normas tributarias vigentes.
      </p>
      <br/>
      <p>El contenido de la factura y su emisión es responsabilidad exclusiva del Restaurante.</p>
      <br/>
      <p>
        <b>
          3.2- Especificaciones aplicables únicamente a las Reservas con Anticipo
          <br/>3.2.1. Funcionamiento
        </b>
      </p>
      <br/>
      <p>
        (i) Para los casos en que el Restaurante haya habilitado Reservas con Anticipo, el Usuario deberá pagar al
        momento de hacer la reservación, el valor que el Restaurante determine.
      </p>
      <br/>
      <p>
        (ii) En el Ticket aparecerá el valor anticipado pagado por el Usuario. Cuando el Restaurante ante el cual se
        hizo la reserva disponga que el Ticket será
        <b>CIEN POR CIENTO (100%)</b> consumible, el Usuario podrá hacerlo
        redimible en su totalidad y pagará directamente en el Restaurante, el excedente consumido , bajo el cumplimiento
        de lo establecido en los presentes Términos y Condiciones. Algunos Restaurantes podrán disponer el porcentaje
        del Ticket que será consumible y el porcentaje que se destinará a otros costos, como por ejemplo, el pago por
        derecho de ingreso al Restaurante.
      </p>
      <br/>
      <p>
        (iii) Para hacer redimible el pago anticipado, el Usuario deberá presentar ante el Restaurante su Ticket e
        identificación en caso de ser requerido por el Restaurante .
      </p>
      <br/>
      <p>
        (iv) En los casos en que el Restaurante determine que el pago anticipado realizado por el Usuario es
        <b>
          CIEN
          POR CIENTO (100%)
        </b> consumible, el valor pagado por el Usuario al hacer la Reserva con Anticipo deberá
        consumirse en su totalidad en el establecimiento y el día de la Reserva con Anticipo, es decir, el Usuario no
        podrá realizar consumos parciales. En ese sentido el Usuario no podrá: (i) solicitar devoluciones de dinero
        cuando el monto que consuma en el Restaurante sea menor al pagado al momento de realizar la Reserva con
        Anticipo; ni, (ii) solicitar que el valor que no consumió pueda ser usado nuevamente cuando haga otra Reserva o
        cuando visite el Restaurante en otra oportunidad.
      </p>
      <br/>
      <p>
        <b>3.2.2. Pagos</b>
      </p>
      <br/>
      <p>
        (i) El Usuario deberá seleccionar el método de pago de la Reserva con Anticipo en el Software. Dependiendo del
        mismo, el Usuario deberá suministrar la información referente a su tarjeta de débito o crédito.
      </p>
      <br/>
      <p>
        (ii) El Usuario deberá pagar ante el Restaurante el valor remanente por los productos consumidos el día de la
        Reserva, el cual será equivalente al valor total del servicio prestado, descontando el valor del Ticket
        adquirido cuando hizo la Reserva con Anticipos a través de el Software.
      </p>
      <br/>
      <p>
        (iii) Se informa al Usuario que, Precompro a través de las pasarelas de pago con las que tiene convenio, se
        encarga de recaudar los pagos realizados por concepto de Reserva con Anticipo. En todo caso, Precompro en su
        calidad de recaudador, realiza la transferencia al Restaurante de los dineros que el Usuario haya pagado por
        concepto de Reserva con Anticipo. Por lo tanto, el Restaurante es el único responsable de descontar el pago
        realizado por el Usuario en su Reserva con Anticipo, de la factura o valor total consumido por el Usuario el día
        que haga efectiva su Reserva. Si el Restaurante olvida descontar de la factura final el valor que el Usuario ya
        ha pagado al momento de hacer la Reserva con Anticipo, el Usuario deberá elevar la reclamación directamente al
        Restaurante, para que el Restaurante realice la devolución o reembolso de su dinero.
      </p>
      <br/>
      <p>
        (iv) Asimismo, el Usuario reconoce y acepta que pagará al Restaurante a través de las pasarelas de pago
        dispuestas en el Software por Precompro el valor del anticipo previamente detallado en el Software de acuerdo
        con el valor determinado por el Restaurante
      </p>
      <br/>
      <p>
        (v) Precompro se reserva el derecho de tomar las medidas judiciales y extrajudiciales que estime pertinentes
        para obtener el pago del monto debido.
      </p>
      <br/>
      <p>
        (vi) Precompro no será responsable por ningún recargo que el Restaurante decida cobrar al Usuario, ni si
        quiera en los eventos en que el Restaurante intente imputar recargos a nombre de Precompro.
      </p>
      <br/>
      <p>
        (vii) En el evento en que el Usuario no pueda demostrar su identificación y haya realizado una Reserva con
        Anticipo, no habrá lugar a devolución del valor del Ticket por parte de Precompro ni del Restaurante.
      </p>
      <br/>
      <p>
        (ix) En los eventos en que el Usuario llegué tarde y cuente con una Reserva con Anticipo no habrá lugar a
        reembolsos ni devolución de dinero.
      </p>
      <br/>
      <p>
        <b>3.3. Políticas, términos y condiciones de los Restaurantes</b>
      </p>
      <br/>
      <p>
        (i) Se informa al Usuario que la información contenida en la presente sección de los Términos y Condiciones,
        no pretende suprimir ni reemplazar las políticas ni los términos y condiciones internos que maneje cada uno de
        los Restaurantes.
      </p>
      <br/>
      <p>
        (ii) Las estipulaciones de la presente sección, serán de carácter complementario a las políticas y los
        términos y condiciones internos que maneje cada uno de los Restaurantes y se aplicarán ante la inexistencia de
        disposiciones específicas en las políticas y los términos y condiciones de cada Restaurante.
      </p>
      <br/>
      <p>
        (iii) Con la aceptación de los presentes Términos y Condiciones el Usuario declara conocer y aceptar las
        políticas internas y los términos y condiciones adicionales previstos por el Restaurante que seleccione.
      </p>
      <br/>
      <p>
        (iv) Ante cualquier contradicción entre la presente sección y las previsiones contenidas en las políticas,
        términos y condiciones de cada Restaurante, primarán las especificaciones establecidas en la presente sección.
        En todo caso, Precompro rechaza y no reconocerá estipulaciones en las cuales el Restaurante pretenda
        responsabilizar a Precompro de obligaciones más allá de las previstas en los presentes Términos y
        Condiciones.
      </p>
      <br/>
      <p>
        <b style="padding-left: 30px">IV. RESPONSABILIDAD</b>
      </p>
      <br/>
      <p>
        Se informa al Usuario que, si bien el control tecnológico del Software y el recaudo de pagos para Reservas con
        Anticipo se encuentra a cargo de Precompro, Precompro no adquiere responsabilidad sobre el servicio prestado por
        el Restaurante, ni participa en la relación de consumo existente entre el Restaurante y el Usuario.
      </p>
      <br/>
      <p>
        Lo anterior por cuanto, Precompro únicamente es un proveedor de servicios tecnológicos que implementa su
        Software para apoyar los procesos de gestión de reservas del Restaurante, sin que propiamente preste servicios
        de comercialización o venta de alimentos y bebidas al público, ni asuma responsabilidades en cuanto al
        cumplimiento del servicio por parte del Restaurante.
      </p>
      <br/>
      <p>
        Para mayor comprensión del Usuario se resaltan las siguientes consecuencias relacionadas con la
        responsabilidad de Precompro:
      </p>
      <br/>
      <p>
        (i) Precompro no tiene como objeto social ni actividad comercial el servicio de restaurante ni el servicio de
        venta y comercialización al público de alimentos y bebidas. Por lo tanto, no será responsable de la calidad,
        precio, estado, idoneidad u oportunidad de los servicios prestados por el Restaurante, como tampoco garantiza o
        dará garantía sobre los mismos.
      </p>
      <br/>
      <p>
        (ii) Los Restaurantes son los directos responsables del servicio que comercializan y de los descuentos,
        promociones y ofertas que ponen a disposición de los Usuarios a través de el Software o de sus canales de
        comunicación.
      </p>
      <br/>
      <p>
        (iii) Los Restaurantes son los únicos responsables frente al Usuario de cumplir con las regulaciones que le
        sean aplicables en materia de información, publicidad, ofertas y promociones, previstas en el Estatuto del
        Consumidor.
      </p>
      <br/>
      <p>
        (iv) El Usuario bajo su responsabilidad realiza la Reserva o Reserva con Anticipo sujeta a los términos y
        condiciones informados por el Restaurante para su acceso y uso, o en su defecto sujeta a los presentes Términos
        y Condiciones.
      </p>
      <br/>
      <p>
        (v) La relación comercial o contractual para acceder a los servicios de Reserva y Reserva con Anticipo, así
        como los servicios propios de restaurante, surge exclusivamente entre el Usuario y el Restaurante. Por lo que,
        Precompro es un tercero ajeno a la relación de consumo y, por ende, no se hace responsable directa ni
        solidariamente por las actuaciones u omisiones del Restaurante.
      </p>
      <br/>
      <p>
        (vi) En todo caso, los Usuarios son quienes de manera libre, consciente y voluntaria adquieren los servicios
        ofrecidos por los Restaurantes sin que Precompro interfiera en su decisión de adquisición.
      </p>
      <br/>
      <p>
        (vii) El único responsable frente a daños, perjuicios, defectos en la prestación del servicio y ejercicio de
        garantías que los Usuarios pudieren reclamar, serán los Restaurantes. Precompro no se responsabiliza por los
        daños y/o perjuicios que puedan ser causados durante la prestación del servicio del Restaurante, ni garantiza el
        nivel de satisfacción esperado por el Usuario .
      </p>
      <br/>
      <p>
        (viii) El Restaurante será el único responsable de realizar el cobro y recaudo de los impuestos aplicables por
        la prestación de su servicios, así como será el único responsable de adelantar el proceso de facturación de los
        servicios y productos que haya proporcionado al Usuario.
      </p>
      <br/>
      <p>
        (ix) Cualquier solicitud, petición queja o reclamo frente a los servicios prestados por los Restaurantes,
        deberá ser dirigida directamente a los Restaurantes de acuerdo con los términos y condiciones que estos
        dispongan.
      </p>
      <br/>
      <p>
        <b style="padding-left: 30px">
          V. ACCESO Y USO DEL SERVICIO
          <br/>
          <u>4.1. Acceso al Sitio Web</u>
        </b>
      </p>
      <br/>
      <p>
        (i) El acceso al Sitio Web y el uso de sus funcionalidades es gratuito, lo anterior quiere decir que cualquier
        persona podrá beneficiarse y hacer uso de este con el simple ingreso.
      </p>
      <br/>
      <p>
        (ii) La persona que no haya cumplido la mayoría de edad dentro del territorio de la República de Colombia,
        podrá acceder al Sitio Web, sin embargo, solo aquellas personas mayores de edad tendrán la facultad para ponerse
        en contacto con Precompro y solicitar información acerca de sus servicios.
      </p>
      <br/>
      <p>
        <b>
          <u>4.2. Acceso a el Software</u>
          <br/>4.2.1. Registro
        </b>
      </p>
      <br/>
      <p>
        (i) Para acceder a las funcionalidades de el Software y realizar la Reserva o Reserva con Anticipo, será
        necesario que se realice previamente un registro y se adquiera la calidad de Usuario. Lo anterior implica, que
        el Usuario ingrese a el Software bajo cualquiera de los canales descritos en la sección número I del presente
        documento y complete toda la información que sea requerida de manera exacta y completa en el formulario
        dispuesto para el efecto por el Software.
      </p>
      <br/>
      <p>
        (ii) Previo al registro el Usuario deberá aceptar la Política de Tratamiento de Datos Personales de Precompro,
        la cual estará disponible para lectura y aceptación del Usuario al momento de realizar la Reserva o Reserva con
        Anticipo.
      </p>
      <br/>
      <p>
        (iii) El registro realizado por el Usuario comporta una aceptación sin reservas de los Términos y Condiciones
        aquí contenidos.
      </p>
      <br/>
      <p>
        <b>4.2.2. Responsabilidad en el suministro de información</b>
      </p>
      <br/>
      <p>
        (i) El Usuario se hace responsable de que todos los datos proporcionados sean válidos, veraces, actuales y de
        su propiedad. Toda la información personal suministrada por el Usuario en el Software se regirá por la Política
        de Tratamiento de Datos Personales de Precompro.
      </p>
      <br/>
      <p>
        (ii) El Usuario será responsable de actualizar su información dentro de el Software, en los casos que deba
        crear una cuenta para su uso.
      </p>
      <br/>
      <p>
        (iii) Precompro no se hace responsable de la veracidad de los datos proporcionados por los Usuarios de el
        Software, ni del uso indebido o no autorizado por parte del Usuario de los datos de terceros.
      </p>
      <br/>
      <p>
        (iv) En todo caso, Precompro se reserva el derecho de implementar medidas que permitan corroborar la identidad
        del Usuario como el reconocimiento de datos biométricos (voz, huella o imagen) o la remisión de códigos
        alfanuméricos enviados directamente al correo electrónico y/o teléfono móvil del Usuario, así como de suspender
        temporal o definitivamente a aquellos Usuarios cuyos datos no hayan podido ser confirmados. En estos casos de
        inhabilitación, se dará de baja la cuenta personal del Usuario, sin que ello genere algún derecho a
        resarcimiento o reembolso de los dineros abonados a la cuenta.
      </p>
      <br/>
      <p>
        <b>4.2.3. Capacidad</b>
      </p>
      <br/>
      <p>
        (i) El Usuario que no haya cumplido la mayoría de edad podrá acceder a el Software. Sin embargo, sólo los
        Usuarios mayores de edad podrán completar el registro para acceder a las funcionalidades de el Software . En
        todo caso, es responsabilidad del Usuario ingresar información veraz y completa acerca de su mayoría de edad,
        pues bien, Precompro de buena fe asumirá que el Usuario que realiza la Reserva o Reserva con Anticipo es mayor
        de edad.
      </p>
      <br/>
      <p>
        (ii) Los servicios ofrecidos por el Software sólo están disponibles para los Usuarios que tengan capacidad
        legal para contratar. No podrán utilizar los servicios los Usuarios que no tengan esa capacidad, los menores de
        edad o los Usuarios de el Software que hayan sido suspendidos temporalmente o inhabilitados
        definitivamente.
      </p>
      <br/>
      <p>
        (iii) En caso de que el Usuario sea una persona jurídica, el Usuario manifiesta que cuenta con capacidad para
        contratar a nombre de tal entidad y de obligar a la misma en los términos del presente documento.
      </p>
      <br/>
      <p>
        <b>4.2.4. Cuenta, claves y seguridad</b>
      </p>
      <br/>
      <p>
        (i) Cuando el Usuario deba crear una cuenta para ingresar a el Software, será único responsable de mantener la
        confidencialidad de su contraseña y credenciales de acceso. De conformidad con lo anterior, el Usuario es el
        único responsable por todas las actividades, solicitudes, compras o manejo de información que ocurran en su
        cuenta.
      </p>
      <br/>
      <p>
        (ii) El Usuario reconoce y acepta que; (a) Solo podrá crear una cuenta; (b) Notificará inmediatamente a
        Precompro de cualquier uso no autorizado de su cuenta y/o clave, o cualquier otra violación de la seguridad, y;
        (c) Se asegurará de cerrar la sesión cada vez que finalice su acceso a el Software.
      </p>
      <br/>
      <p>
        (iii) Precompro no será responsable por cualquier pérdida o daño que se llegue a presentar a causa del
        incumplimiento de esta sección.
      </p>
      <br/>
      <p>
        (iv) En caso de que Precompro detecte distintas cuentas que contengan datos coincidentes o relacionados, podrá
        cancelar, suspender o inhabilitarlas, sin que esto genere ningún derecho a favor del Usuario de reparación o
        reembolso de los dineros abonados.
      </p>
      <br/>
      <p>
        <b style="padding-left: 30px">VI. DERECHO DE RETRACTO</b>
      </p>
      <br/>
      <p>
        (i) Al realizar el Usuario una Reserva con Anticipo a través de el Software, podrá ejercer el derecho de
        retracto dentro de los cinco (5) días hábiles siguientes a partir de la recepción de su Ticket. El derecho de
        retracto aplica, siempre y cuando el servicio no haya tenido que ejecutarse durante los mismos cinco (5) días
        hábiles. En todo caso, el retracto no se podrá ejercer si la prestación del servicio ya ha comenzado.
      </p>
      <br/>
      <p>
        (ii) Se informa al Usuario que la antelación con la cual puede ejercerse el derecho de retracto podrá variar
        de conformidad con las políticas de cancelación y/o modificación de cada Restaurante, sin que en todo caso la
        antelación deba ser superior a la establecida en el Estatuto del Consumidor para el ejercicio de este
        derecho.
      </p>
      <br/>
      <p>(iii) El Usuario deberá ejercer el derecho de retracto directamente frente al Restaurante.</p>
      <br/>
      <p>
        (iv) Para los casos en los que un Usuario ejerza el derecho de retracto o realice la cancelación de su Reserva
        con Anticipo, Precompro previa solicitud del Restaurante, solicitará a la pasarela de pago la reversión del
        cargo;
      </p>
      <br/>
      <p>
        <b style="padding-left: 30px">VII. PASARELA DE PAGOS</b>
      </p>
      <br/>
      <p>
        En caso de que el Usuario realice un pago por PSE, Precompro incorpora a el Software ciertas integraciones,
        dentro de las cuales se encuentran las pasarelas de pagos mediante las cuales el Usuario es remitido al ambiente
        tecnológico de la institución financiera o intermediario de pago , con el que realizará el pago de la Reserva
        con Anticipo que pretenda adquirir a través de el Software.
      </p>
      <br/>
      <p>
        Este proceso, es realizado por el Usuario directamente a través de la institución financiera o intermediario
        de pago, por ello, teniendo en cuenta que el enlace puede dirigir a ambientes tecnológicos de terceros, el
        Usuario al usar la pasarela de pagos abandona el sitio operado por Precompro y desde ese momento se encuentra
        sujeto a las políticas de tratamiento de datos personales y políticas de privacidad de dichos terceros.
      </p>
      <br/>
      <p>
        <b style="padding-left: 30px">
          VIII. DISPONIBILIDAD DE LAS PLATAFORMAS TECNOLÓGICAS
          <br/>
          <u>7.1. Acceso a la Plataforma</u>
        </b>
      </p>
      <br/>
      <p>
        El Usuario es responsable de obtener acceso a la red de datos necesarios para el uso de las Plataformas
        Tecnológicas. Podrán aplicarse tarifas y tasas de datos y mensajes de la red móvil del Usuario. El Usuario es
        responsable por el pago de dichas tarifas y tasas, así como, de la adquisición y actualización del hardware
        compatible o los dispositivos necesarios para acceder y utilizar las Plataformas Tecnológicas y cualquier
        actualización de estos.
      </p>
      <br/>
      <p>
        <b>
          <u>7.2. Disponibilidad</u>
        </b>
      </p>
      <br/>
      <p>
        Precompro no garantiza la disponibilidad y continuidad del funcionamiento de Las Plataformas Tecnológicas. En
        consecuencia, Precompro no será en ningún caso responsable por cualesquiera daños y perjuicios que puedan
        derivarse de:
      </p>
      <br/>
      <div style="padding-left: 30px">
        <p>(a) La falta de disponibilidad o accesibilidad a las Plataformas Tecnológicas.</p>
        <p>
          (b) La interrupción en el funcionamiento de las Plataformas Tecnológicas o fallos informáticos,
          desconexiones,
          retrasos o bloqueos causados por deficiencias o sobrecargas en los centros de datos, en el sistema de
          internet,
          en las redes de telecomunicaciones o en otros sistemas electrónicos.
        </p>
        <p>
          (c) Lentitud, errores, retrasos o cualquier anomalía en el acceso por parte del Usuario a la hora de
          ingresar
          a el Software que pueda surgir por problemas en la red de internet, en las redes de telecomunicaciones causas
          de
          caso fortuito o fuerza mayor y cualquier otra contingencia imprevisible ajena a la buena fe de Precompro.
        </p>
        <p>
          (d) Las pérdidas, daños o perjuicios de cualquier tipo que surjan durante el acceso o uso de las Plataformas
          Tecnológicas, tales como los que se produzcan por la introducción de virus y/o ataques informáticos.
        </p>
        <p>
          (e) Orden de autoridad competente y/o gubernativa que no permita la correcta ejecución y/o operatividad de
          las
          Plataformas Tecnológicas, por una causa no atribuible a Precompro.
        </p>
        <p>(f) Culpa exclusiva del Usuario.</p>
        <p>
          (g) Precompro no garantiza la ausencia de virus ni de otros elementos en las Plataformas Tecnológicas
          introducidos por terceros ajenos a Precompro que puedan producir alteraciones en los sistemas físicos o
          lógicos
          del Usuario o en los documentos electrónicos y ficheros almacenados en sus sistemas.
        </p>
        <p>
          (h) Precompro no será responsable por la pérdida o falla de la información que pueda presentarse por el
          mantenimiento o actualización de las Plataformas Tecnológicas.
        </p>
        <p>
          (i) Otros daños que puedan ser causados por terceros mediante intromisiones no autorizadas ajenas al control
          de Precompro.
        </p>
      </div>
      <br/>
      <p>
        Precompro en ninguna circunstancia será responsable por daños directos, daños indirectos, daños incidentales,
        daños especiales, daños ejemplares o punitivos, lucro cesante, pérdida de datos, lesiones personales, daño a la
        propiedad y/o daños consecuenciales que no sean probados por el Usuario.
      </p>
      <br/>
      <p>
        Sin perjuicio de lo anterior, Precompro se compromete realizar todos sus mayores esfuerzos para solucionar los
        problemas que puedan surgir y a ofrecer todo el apoyo necesario a los Usuarios para llegar a una solución rápida
        y satisfactoria de la incidencia.
      </p>
      <br/>
      <p>
        En todo caso, Precompro sólo responderá de aquellos daños que los Usuarios pudieran sufrir como consecuencia
        de una actuación dolosa, o manifiestamente negligente por parte de Precompro, en relación con el acceso a las
        Plataformas Tecnológicas cuando dichos daños estén debidamente probados.
      </p>
      <br/>
      <p>
        <b style="padding-left: 30px">IX. PROHIBICIONES</b>
      </p>
      <br/>
      <p>
        Los Usuarios se abstendrán de realizar los siguientes actos al navegar en el Sitio Web o hacer uso de el
        Software:
      </p>
      <br/>
      <div style="padding-left: 40px">
        <p>(a) Acceder o intentar acceder a recursos restringidos de las Plataformas Tecnológicas.</p>
        <p>(b) Utilizar de manera fraudulenta las Plataformas Tecnológicas.</p>
        <p>(c) Entregar sus credenciales o datos de acceso a las Plataformas a terceros.</p>
        <p>
          (d) Registrar o entregar información de métodos de pago de terceras personas sin contar con su
          autorización.
        </p>
        <p>
          (e) Hacerse pasar por cualquier persona natural jurídica, o falsamente representar vínculo con otra persona
          natural o jurídica.
        </p>
        <p>(f) Recolectar información personal de otros Usuarios mediante cualquier medio.</p>
        <p>(g) Registrar métodos de pago fraudulentos.</p>
        <p>
          (h) Usar las Plataformas Tecnológicas con fines ilícitos, ilegales, contrarios a la moral y al orden
          público,
          lesivos de intereses o derechos de terceros, o de cualquier forma que pueda dañar o impedir la normal
          navegación
          o uso de las Plataformas.
        </p>
        <p>(i) Compartir o promover cualquier actividad criminal o información acerca de actividades ilícitas.</p>
        <p>
          (j) Difundir virus informáticos o cualquier software malicioso que pueda producir daños en los servidores de
          Precompro, el Restaurante o en las Plataformas Tecnológicas.
        </p>
        <p>
          (k) Manipular o intentar acceder a los datos de Precompro, de terceros o de los demás Usuarios de las
          Plataformas Tecnológicas.
        </p>
        <p>
          (l) Usar programas automáticos, mecanismos o procesos manuales para monitorear, copiar, resumir, o extraer
          información de cualquier otra forma desde las Plataformas Tecnológicas.
        </p>
        <p>
          (m) Usar las cuentas y claves de otros Usuarios, o cualquier otra información sin el consentimiento previo y
          por escrito de su titular.
        </p>
        <p>
          (n) Transmitir desde las Plataformas Tecnológicas SPAMs, cadenas, correo basura o cualquier otro tipo de
          correo masivo no solicitado.
        </p>
        <p>
          (o) Realizar uso de ingeniería inversa para descifrar, descompilar o de cualquier otro modo acceder al
          código
          fuente de las Plataformas Tecnológicas o cualquier otro elemento sujeto a derechos de propiedad
          intelectual.
        </p>
        <p>
          (p) Realizar acciones o utilizar medios tendentes a alterar la apariencia o las funcionalidades de las
          Plataformas Tecnológicas.
        </p>
        <p>(q) Acceder o intentar acceder a las cuentas de otros Usuarios.</p>
      </div>
      <p>
        Precompro podrá suprimir el Software o suspender el uso de esta al Usuario que incurra en cualquiera de los
        actos aquí previstos o que no cumpla con las obligaciones previstas en los Términos y Condiciones, remover del
        contenido violatorio de las Plataformas Tecnológicas, así como se reserva el derecho de denunciar ante las
        autoridades correspondientes cualquier actividad que en consideración de Precompro viole estas
        provisiones.
      </p>

      <br/>
      <p>
        <b style="padding-left: 30px">X. PROPIEDAD INTELECTUAL</b>
      </p>
      <br/>
      <p>
        Las Plataformas Tecnológicas pertenecen a Precompro. Sus contenidos, incluyendo, pero sin limitarse al código
        fuente, propiedad industrial, imágenes y diseños están protegidos, de acuerdo con las normas colombianas por
        derechos de autor, marcas registradas y otros derechos de propiedad intelectual e industrial de titularidad de
        Precompro. El acceso a las Plataformas Tecnológicas no confiere en ningún caso, ni podrá ser interpretado como
        autorización o licencia de ningún tipo sobre tales contenidos y derechos.
      </p>
      <br/>
      <p>
        Asimismo, ciertos nombres comerciales, marcas, logotipos, eslóganes y otros materiales expuestos en las
        Plataformas Tecnológicas están debidamente registrados ya sea a nombre de Precompro, o a nombre de los
        Restaurantes que hacen uso del servicio ofrecido por Precompro. Por lo anterior, los Usuarios no están
        autorizados para utilizar ninguno de dichos elementos, cuya propiedad permanecerá, en todo caso, bajo
        titularidad de Precompro o del Restaurante dueño de los signos distintivos.
      </p>
      <br/>
      <p>
        Queda expresamente prohibido cualquier acto de reproducción, distribución, transformación o comunicación
        pública, así como cualquier tipo de cesión, de todo o parte del contenido de las Plataformas Tecnológicas y, en
        general, cualquier acto de explotación de la totalidad o parte de los contenidos (imágenes, textos, diseño,
        índices, formas, etc.) así como de las posibles bases de datos que el Sitio Web y/o el Software pudiera contener
        y de cualquier objeto, que sea protegible según la legislación vigente, especialmente conforme a la normativa de
        propiedad intelectual e industrial.
      </p>
      <br/>
      <p>
        Precompro se reserva la facultad de iniciar las acciones civiles y penales a que haya lugar por el
        incumplimiento del Usuario de los términos aquí previstos.
      </p>
      <br/>
      <p>
        <b style="padding-left: 30px">XI. POLÍTICA DE PRIVACIDAD</b>
      </p>
      <br/>
      <p>
        Sin perjuicio de los establecido en la Política de Tratamiento de Datos Personales de Precompro, se hacen al
        Usuario ciertas precisiones sobre el manejo de la información dentro de las Plataformas Tecnológicas:
      </p>
      <br/>
      <p>
        <b>10.1. ¿Qué información se recolecta través de las Plataformas Tecnológicas?</b>
      </p>
      <br/>
      <p>
        Es posible que Precompro solicite al Usuario que hace uso de las Plataformas Tecnológicas la siguiente
        información:
      </p>
      <br/>
      <p>
        <u>Información de identificación personal</u>
      </p>
      <br/>
      <p>
        A través de las Plataformas Tecnológicas, se recolecta información del Usuario para reconocerlo e identificarlo,
        como su nombre, dirección de correo electrónico, número de celular, fecha de nacimiento y género. En caso de que
        el Usuario realice el registro en el Software a través de una cuenta Google o Facebook, se recopilará la
        dirección de correo electrónico vinculada a dichas cuentas de acuerdo con la configuración de privacidad
        establecida en la cuenta de Google o Facebook del Usuario.
      </p>
      <br/>
      <p>
        <u>Datos de registro</u>
      </p>
      <br/>
      <p>
        Las Plataformas Tecnológicas recopilan datos e información almacenada en el teléfono del Usuario denominada
        datos de registro los cuales pueden incluir información como la dirección de Protocolo de Internet o dirección
        “IP” de su dispositivo, nombre del dispositivo, versión del sistema operativo, la hora y fecha de uso del
        servicio y otras estadísticas.
      </p>
      <br/>
      <p>
        <u>Información enviada por el dispositivo móvil del Usuario</u>
      </p>
      <br/>
      <p>
        El dispositivo móvil de un Usuario envía cierta información a las Plataformas Tecnológicas, la cual es
        recolectada por Precompro. Dentro de dicha información, se encuentran el sistema operativo del Dispositivo,
        navegador usado en el Dispositivo y la versión del sistema operativo del Dispositivo, así como información sobre
        el uso que realiza el Usuario de las Plataformas Tecnológicas.
      </p>
      <br/>
      <p>
        <b>10.2. ¿Para qué se recopila información del Usuario?</b>
      </p>
      <br/>
      <p>
        Precompro recopila información de los Usuarios que ingresan y hacen uso de las Plataformas Tecnológicas. La
        información es suministrada directamente por el Usuario y tiene las siguientes finalidades:
      </p>
      <br/>
      <div style="padding-left: 30px">
        <p>
          - Proporcionar los servicios de Precompro, así como optimizarlos, mejorarlos y actualizarlos de conformidad
          con
          el uso que realice el Usuario de las Plataformas Tecnológicas.
        </p>
        <p>- Desarrollar nuevos servicios, funcionalidades y aplicaciones.</p>
        <p>- Administrar el uso que los Usuarios hacen de el Software.</p>
        <p>
          - Permitir a los Usuarios la correcta navegación en las Plataformas Tecnológicas y mejorar su
          experiencia.
        </p>
        <p>- Comprender como son utilizados los servicios que ofrece el Software y optimizar su diseño.</p>
        <p>- Validar la identidad del Usuario que utiliza las Plataformas Tecnológicas.</p>
        <p>
          - Contactar al Usuario y enviarle notificaciones a través de los canales de contacto proporcionados por este o
          a
          través de las Plataformas Tecnológicas.
        </p>
        <p>
          - Negar el acceso a el Software cuando se verifique que la información que se está proporcionando es falsa o
          inexacta.
        </p>
        <p>
          - Compartirla con los trabajadores y personal autorizado de los Restaurantes para que estos puedan gestionar
          directamente su proceso de reservas virtual.
        </p>
      </div>
      <br/>
      <p>
        <b>10.3. Medidas de seguridad</b>
      </p>
      <br/>
      <p>
        Es importante que el Usuario tenga conocimiento que ningún método de transferencia de información en Internet es
        completamente seguro o está libre de riesgos. Lo mismo ocurre con la información que es almacenada dentro de una
        aplicación.
      </p>
      <br/>
      <p>
        A pesar de lo anterior, Precompro adopta todas las medidas técnicas, administrativas, físicas y electrónicas a
        su alcance para proteger la información del Usuario de destrucciones ilegales, perdida, uso, acceso, divulgación
        o interferencias no autorizadas.
      </p>
      <br/>
      <p>
        Precompro procura que la información del Usuario solo sea conocida por el personal o trabajadores del
        Restaurante o de Precompro que por la naturaleza de sus funciones deban procesarla o administrarla, limitando el
        acceso a través de claves de ingreso al sistema y credenciales conferidas solo al personal autorizado. De igual
        manera, la información recopilada está sujeta a cláusulas contractuales y acuerdos de confidencialidad por parte
        tanto de trabajadores como de clientes y proveedores.
      </p>
      <br/>
      <p>
        Precompro se esfuerza porque el Software este actualizado y en buen funcionamiento en todo momento. Se recuerda
        al Usuario que debe mantener la seguridad de su Dispositivo por lo que es recomendable que no haga jailbreack,
        es decir eliminar limitaciones o restricciones que impone directamente el sistema operativo del Dispositivo,
        ello por cuanto podría ser vulnerable a malware, virus o programas maliciosos que puedan afectar la seguridad
        del Dispositivo, y podrían causar errores de funcionamiento en las Plataformas Tecnológicas.
      </p>
      <br/>
      <p>
        <b>10.4. Decisiones del Usuario</b>
      </p>
      <br/>
      <p>
        <u>Revocar la autorización</u>
      </p>
      <br/>
      <p>
        El Usuario puede notificar a Precompro y solicitar la revocatoria de la autorización que ha conferido para la
        recolección, uso y/o divulgación de su información en cualquier momento, enviando un correo a la dirección
        electrónica contacto@precompro.com. Precompro interrumpirá el acceso del Usuario y la utilización de el
        Software, y se asegurará de que sus datos de identificación o aquellos utilizados para reconocerle no sean
        públicamente accesibles a través de el Software.
      </p>
      <br/>
      <p>
        No obstante, el Usuario debe tener en cuenta que de solicitar la revocatoria de la autorización para la
        recolección, uso y/o divulgación de su información, es posible que ya no pueda tener acceso o usar las
        funcionalidades ofrecidas por el Software.
      </p>
      <br/>
      <p>
        <u>Acceso y solicitud de eliminación de la información</u>
      </p>
      <br/>
      <p>
        El Usuario podrá solicitar en todo momento a Precompro, el acceso a la información almacenada sobre este, así
        como podrá solicitar la modificación de su información. De igual manera, el Usuario podrá en cualquier momento
        requerir a Precompro la eliminación parcial o total de su información, o la eliminación de la cuenta creada
        dentro de el Software. Todo lo anterior deberá solicitarlo dirigiendo un correo a la dirección electrónica
        contacto@precompro.com Precompro adoptará las medidas necesarias para dar acceso al Usuario a su información
        modificarla o eliminarla dentro de los términos previstos en la ley colombiana y para el caso de datos
        personales, de acuerdo con los procedimientos previstos en la Política General de Tratamiento de Datos
        Personales prevista en la siguiente dirección: https://www.precompro.com_____
      </p>
      <br/>
      <p>
        En todo caso, se informa al Usuario que la eliminación de su cuenta o información de la aplicación dará lugar a
        que no pueda tener acceso a los servicios que esta ofrece .
      </p>
      <br/>
      <p>
        De igual manera, el Usuario reconoce que la información será transferida a los Restaurantes para que estos
        puedan adelantar los procesos de administración y gestión de Reservas. Por esta razón, Precompro únicamente será
        responsable de la eliminación de la información que se encuentre en sus servidores. Si el Usuario desea que su
        información sea eliminada de las bases de datos y servidores de los Restaurantes, deberá elevar esta petición
        directamente al Restaurante.
      </p>
      <br/>
      <p>
        <b>10.5. Vínculos o enlaces a terceros:</b>
      </p>
      <br/>
      <p>
        Las Plataformas Tecnológicas puede contener vínculos, hipervínculos o links que dirijan a otros sitios web que
        no sean controlados, editados, ni tengan relación alguna con Precompro. Por lo tanto, Precompro no se hace
        responsable del contenido ni de la exactitud de la información almacenada en ellos. La función de los vínculos
        que se encuentren en la Plataforma es meramente informativa, es proporcionada por terceros, y se limita sólo a
        dar a conocer al Usuario otras fuentes de información relacionadas a los servicios de las Plataformas
        Tecnológicas o del Restaurante. El establecimiento de estos vínculos no implica la existencia de algún tipo de
        relación entre Precompro y el titular de la página web en la que se establezcan los mismos.
      </p>
      <br/>
      <p>
        <b style="padding-left: 30px">XII. USO COMERCIAL DE LOS TICKECTS</b>
      </p>
      <br/>
      <p>
        A menos que sea expresamente autorizado por Precompro, el Usuario, Restaurante o cualquier tercero deberán
        abstenerse de desarrollar, reproducir, duplicar, copiar, modificar, enmarcar, rentar, prestar, vender,
        re-vender, distribuir, o enajenar a cualquier título los Tickets adquiridos a través de el Software, cualquier
        otro servicio o característica del servicio basadas en parte o en total, en el Software desarrollada por
        Precompro.
      </p>
      <br/>
      <p>
        <b style="padding-left: 30px">XIII. RESPONSABILIDAD POR LA INFORMACIÓN CONTENIDA</b>
      </p>
      <br/>
      <p>
        Debido a la cantidad de medios técnicos que no permiten garantizar la absoluta injerencia de la acción de
        terceras personas en los sitios publicados en Internet, Precompro no garantiza la exactitud y/o veracidad de
        todo o parte de la información contenida en las Plataforma Tecnológicas, ni su actualización, ni que dicha
        información haya sido alterada o modificada en todo o en parte, luego de haber sido incluida en la misma, ni
        cualquier otro aspecto o característica de la información brindada por medio de las Plataformas Tecnológicas, o
        a través de los vínculos eventualmente incluidos en el mismo.
      </p>
      <br/>
      <p>
        <b style="padding-left: 30px">XIV. TERMINACIÓN DEL SERVICIO</b>
      </p>
      <br/>
      <p>
        El Usuario reconoce y acepta que Precompro bajo su propia discreción podrá suspender o terminar la cuenta, o
        parte de esta, con o sin notificación previa por cualquier razón, incluyendo la sospecha por parte de Precompro
        de violación de los Términos y Condiciones, o en los casos en que el Restaurante así lo solicite.
      </p>
      <br/>
      <p>
        <b style="padding-left: 30px">XV. ARBITRAJE</b>
      </p>
      <br/>
      <p>
        Toda controversia o diferencia relativa a los presentes Términos y Condiciones y al cumplimiento de cualquiera
        de las obligaciones señaladas en el presente documento entre el Usuario y Precompro (" LAS PARTES"), se
        resolverá por un Tribunal de Arbitramento gratuito para MiPyMEs, presentando ante el Centro de Arbitraje y
        Conciliación de la Cámara de Comercio de Bogotá para MiPyMEs, la respectiva solicitud. El Tribunal de
        Arbitramento se regirá de acuerdo con las siguientes reglas: (i) El Tribunal estará integrado por un (1) arbitro
        elegido de común acuerdo por LAS PARTES, de la lista que para tal efecto lleve el Centro de Arbitraje y
        Conciliación de la Cámara de Comercio de Bogotá para MiPyMEs. En el evento que no haya acuerdo, LAS PARTES
        delegan expresamente en el Centro de Conciliación y Arbitraje Empresarial la designación de los árbitros
        conforme a lo señalado en el reglamento interno; (ii) El Tribunal funcionará de conformidad con el reglamento de
        Arbitraje MiPyME del Centro de Arbitraje y Conciliación de la Cámara de Comercio de Bogotá; (iii) El Tribunal
        decidirá en derecho; (iv) El Secretario del Tribunal de Arbitramento será elegido de la lista oficial de
        secretarios que para tal efecto lleve el Centro de Arbitraje y Conciliación de la Cámara de Comercio de Bogotá
        para MiPyMEs; (v) El Tribunal sesionará en las instalaciones de la Cámara de Comercio de Bogotá para
        MiPyMEs.
      </p>
      <br/>
      <p>
        <b style="padding-left: 30px">XVI. MISCELÁNEOS</b>
      </p>
      <br/>
      <p>
        <b>Anexos:</b>
        Hacen parte integral de los presentes Términos y Condiciones, la Política de Tratamiento de Datos Personales de
        Precompro disponible en:__________
      </p>
      <br/>
      <p>
        <b>Ley aplicable y jurisdicción:</b>
      </p>
      <br/>
      <p>
        Los presentes Términos y Condiciones están regulados por las leyes de la República de Cualquier controversia
        derivada del presente acuerdo, su existencia, validez, interpretación, alcance o cumplimiento será a las leyes
        aplicables.
      </p>
      <br/>
      <p>
        <b>Domicilio y notificaciones:</b>
      </p>
      <br/>
      <p>
        Se fija como domicilio principal la ciudad de Bogotá, Las notificaciones judiciales serán recibidas en la
        Carrera 5 # 92-27; Teléfono: 3132195332; Correo electrónico: contacto@precompro.com.
      </p>
      <br/>
      <p>
        <b>Modificaciones:</b>
      </p>
      <br/>
      <p>
        Sin previo aviso y siempre y cuando esto no implique una afectación de los derechos adquiridos por el Usuario,
        los presentes Términos y Condiciones podrán ser modificados total o parcialmente entre otros, por los siguientes
        motivos: (i) Variaciones de las características de Precompro; (ii) Cambios tecnológicos que afecten el (los)
        servicio (s); (iii) variaciones de las condiciones económicas existentes en el momento de la contratación del
        servicio; y, (iv) Evolución del mercado.
      </p>
      <br/>
      <p>
        Las características del servicio podrán verse modificadas con la finalidad de adaptarlas a su evolución técnica,
        cuando existan razones para Precompro podrá realizar cualquier mejora técnica que permita incorporar un mayor
        número de características y prestaciones al servicio ofrecido. Dichas modificaciones surtirán sus efectos
        inmediatamente, una vez que se hayan publicado en las Plataformas Tecnológicas. En caso de que el Usuario no
        esté de acuerdo con la modificación de las condiciones, deberá informarlo por correo electrónico para darlo de
        baja en la prestación del servicio.
      </p>
      <br/>
      <p>
        Así mismo, Precompro se reserva la posibilidad de modificar sin previo aviso el diseño, presentación y/o
        configuración de las Plataformas Tecnológicas, así como algunos o todos los servicios, y añadir servicios
        nuevos.
      </p>
    </div>
    <hr v-if="params.terminos"/>
    <div class="terminos-restaurante" v-if="(params.activeUrlFileTermsConditions === 0 && params.terminos) || (params.activeUrlFileTermsConditions === 1 && (params.urlTerminos && params.urlTerminos !== ''))">
      <strong>Términos y condiciones del restaurante</strong>
      <a :href="params.activeUrlFileTermsConditions === 0 ? params.terminos : params.urlTerminos" target="blank">Ver</a>
    </div>
  </div>
</template>
<script>
export default {
  props: ['fecha'],
  methods: {
    convertTime (time) {
      // validar si es numero
      if (isNaN(time) || time == null || time === undefined || time < 0) {
        return 0;
      } else {
        return parseInt(time) / 3600000;
      }
    }
  }
};
</script>
<style lang="scss">
#terms-html2 {
  height: 500px;
  overflow: auto;
  text-align: justify;
  padding-right: 5px;
  background: white;
  padding: 30px;

  &::-webkit-scrollbar {
    width: 3px;
  }
}

.terminos-restaurante {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  a {
    font-size: 25px;
    color: black;
    text-decoration: underline lightgray;
  }
}
</style>
